import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Academy({ styles, elements, ...props }) {
  const Elements = useComponents()
  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 0
  const checkboxesColor = styles.forElement("form_header").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <div
        className="formkit-page-header"
        style={styles.forElement("page_header")}
      >
        <div className="formkit-container">
          <Elements.Heading
            className="formkit-header"
            name="header"
            defaults={{ content: "Enter a catchy page headline" }}
          />
          <Elements.Content
            name="subheader"
            defaults={{
              content: "Provide more detail in this subtitle"
            }}
          />
        </div>
      </div>
      <div className="formkit-content">
        <div className="formkit-container">
          <Elements.Video name="video" />
          <Elements.Region className="formkit-content-subheader" name="content">
            <Elements.Content
              defaults={{
                content: "Add detailed content here"
              }}
            />
          </Elements.Region>
          <Elements.Form
            style={styles.forElement("form", {
              backgroundColor: "#ffffff"
            })}
          >
            <div
              className="formkit-form-background"
              style={{
                backgroundColor: styles.forElement("form_background")
                  .backgroundColor
              }}
            />
            <div
              className="formkit-form-background"
              style={styles.forElement("form_background")}
            />
            <Elements.Heading
              className="formkit-form-header"
              name="form_header"
              defaults={{ content: "Join the newsletter" }}
            />
            <Elements.Errors />
            <Elements.CustomFields
              style={{
                color: checkboxesColor
              }}
              data-stacked={stackedDisplay}
              fields={[
                {
                  name: "email_address",
                  label: "Your email address",
                  type: "email",
                  required: true
                }
              ]}
            >
              {stackedDisplay && <Elements.AddFieldButton />}
              <Elements.Button
                name="submit"
                group="button"
                defaults={{ content: "I'm in!" }}
              />
            </Elements.CustomFields>
            {!stackedDisplay && <Elements.AddFieldButton />}
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </Elements.Form>

          <Elements.Region
            className="formkit-content-subheader"
            name="additional_content"
          >
            <Elements.Content
              defaults={{
                content: "Add more detailed content here"
              }}
            />
          </Elements.Region>
          <Elements.Image
            className="formkit-image"
            name="signature"
            size={{ w: 150, h: 150 }}
          />
        </div>
      </div>
      <Elements.BuiltWith background="page" />
    </Elements.LandingPage>
  )
}

Academy.style = "landing_page"
Academy.categories = ["Video"]
Academy.thumbnail = ""
Academy.preview = "https://pages.convertkit.com/7cfa823aae/2a42117b2d"
Academy.fields = [
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Academy, { name: "Academy" })
